*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html,
#root,
.site {
  height: 100%;
  font-family: 'Montserrat', sans-serif;
}

.site {
  margin: 0;
  padding: 0;
  width: 100%;
}

#home {
  height: 100%;
  min-height: 500px;
  width: 100%;
  overflow: hidden;
  background-image: linear-gradient(to bottom,
      rgba(245, 245, 245, 1),
      rgba(245, 245, 245, 0)),
    url('./img/WoodShavings.jpg');
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

#nav-bar {
  width: 100%;
  height: 66px;
  border-bottom: solid 1px #c5c4c4;
  position: fixed;
  background-color: #f5f5f5;
  z-index: 10;
}

#nav-bar-logo {
  width: 150px;
  margin-inline-start: 20px;
}

#nav-bar-links,
#nav-bar-links-mobile {
  width: 500px;
  margin-right: 10px;
}

.nav-bar-link,
.nav-bar-link-mobile {
  display: block;
  text-decoration: none;
  text-align: center;
  color: #062549;
  width: 90px;
}

.nav-bar-link:hover,
.nav-bar-link-mobile:hover {
  color: #35c0ed;
}

#home-main-logo {
  width: 45vw;
  min-width: 400px;
  margin: auto;
  padding-top: 25vh;
}

#home-footer {
  background-color: #f5f5f5cb;
  width: 100%;
  height: fit-content;
  padding: 1.5em 0 1.3em 0;
  position: absolute;
  bottom: 3%;
}

.home-footer-fig {
  width: max-content;
  margin: 0;
  padding: 0;
}

figcaption {
  font-size: 1.3em;
  font-weight: 450;
  text-align: center;
  height: 40%;
  margin: 0 !important;
  color: #062549;
  padding-top: 10px;
  width: max-content;
}

#home-footer-icons {
  padding: 0 8vw;
}

.home-footer-icon {
  color: #062549;
  width: 16vw;
}

.fa-circle {
  color: transparent;
  -webkit-text-stroke: 3px #8d8b8b !important;
}

.arrow {
  display: none;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.arrow span {
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 5px solid #9aeba6;
  border-right: 5px solid #9aeba6;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

#about {
  background-color: #062549;
  background-image: linear-gradient(to bottom,
      rgba(6, 37, 73, 0.8),
      rgba(6, 37, 73, 0.4)),
    url('./img/AngleGrinder.jpg');
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  position: relative;
  padding: 9% 0;
  border-top: 66px solid #f5f5f5;
  overflow: hidden;
}

#about-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  min-height: 520px;
  font-size: 0.9em;
  color: #fff;
  letter-spacing: 4px;
  padding: 3em;
  margin: auto;
  line-height: 2;
}

#projects {
  background-color: #062549;
  color: #f5f5f5;
  line-height: 1.7;
  min-height: 500px;
  border-top: 66px solid #f5f5f5;
}

#slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 66px);
  min-height: 500px;
}

.project-slide-c {
  position: absolute;
  height: 100%;
  width: 60%;
  opacity: 0;
  transition: opacity 2s ease-in-out;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  left: 20%;
}

.project-slide-l {
  position: absolute;
  height: 100%;
  width: 60%;
  opacity: 0;
  transition: opacity 2s ease-in-out;
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
  left: -40%;
}

.project-slide-r {
  position: absolute;
  height: 100%;
  width: 60%;
  opacity: 0;
  transition: opacity 2s ease-in-out;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  right: -40%;
}

.project-slide-c.current {
  opacity: 1;
}

.project-slide-l.left {
  opacity: 0.5;
}

.project-slide-r.right {
  opacity: 0.5;
}

.hidden-project-description {
  opacity: 0;
}

.project-slide-c .project-description {
  position: absolute;
  bottom: -350px;
  left: 20px;
  width: 600px;
  max-width: 90%;
  opacity: 0;
  background-color: #f5f5f5cb;
  color: #062549;
  padding: 2.2em;
}

.project-description h3 {
  margin-bottom: 0.8em;
}

.project-slide-c.current .project-description {
  opacity: 1;
  transform: translateY(-370px);
  transition: all 0.8s ease-in-out 0.2s;
}

.arrow-buttons button {
  border: 3px solid #4e4e4e;
  background: #f5f5f5;
  opacity: 0.4;
  color: #4e4e4e;
  cursor: pointer;
  padding: 1em 1.4em;
  outline: none;
}

.arrow-buttons button:hover {
  opacity: 0.8;
}

.arrow-buttons button#prev {
  position: absolute;
  top: 40%;
  left: 10%;
}

.arrow-buttons button#next {
  position: absolute;
  top: 40%;
  right: 10%;
}

#services {
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  min-height: 100%;
  border-top: 66px solid #f5f5f5;
  background-color: #f5f5f5;
  color: #062549;
}

.service-box {
  height: fit-content;
  min-height: 595px;
  width: 280px;
  padding: 25px 40px 20px 40px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(90deg, #35c0ed, #9aeba6 50%, #35c0ed) border-box;
  border-top: 4px solid transparent;
  border-inline: 1px solid #c5c5c4;
  border-bottom: 1px solid #c5c5c4;
  text-align: center;
  font-size: 0.9em;
}

.service-box p {
  min-height: 151.2px;
}

.service-box ul {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1.7em;
}

.service-box p,
.service-box ul {
  color: #4e4e4e;
  margin: 0;
}

.service-icon {
  margin-block: 10px 25px;
  text-align: center;
  width: 150px;
}

.service-box h4 {
  margin: 0 !important;
  padding: 0 !important;
}

.service-box hr {
  margin: 30px;
}

#contact {
  position: relative;
  height: 95%;
  min-height: 620px;
  width: 100%;
  overflow: hidden;
  background-color: #062549;
  border-top: 66px solid #f5f5f5;
}

#contact-card {
  position: absolute;
  width: 45%;
  height: 80%;
  min-height: 450px;
  top: 10%;
  right: 4%;
  color: #9aeba6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-block-end: 20px;
}

#contact-title {
  font-size: 2.5em;
  letter-spacing: 0.15em;
  margin: 0;
}

#map-shot {
  height: 65%;
  width: 80%;
  border-radius: 3px;
}

#contact-details {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
  color: #f5f5f5;
}

#contact-details div {
  display: flex;
  align-content: center;
  justify-content: center;
}

#contact-details i {
  font-size: 1.5em;
  margin-inline-end: 0.4em;
  color: #9aeba6;
}

#contact-details-phone-number a {
  text-decoration: none;
  color: inherit;
}

#social-media {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  color: #f5f5f5;
}

#social-media-message {
  display: inline-block;
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

#social-media a {
  text-decoration: none;
}

#social-media i {
  font-size: 1.3em;
}

#social-media-buttons {
  display: flex;
  justify-content: center;
  gap: 1em;
  width: 100%;
}

.social-media-button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 8.5em;
  padding: 0.5em;
  border-radius: 3px;
  background-color: #9aeba6;
  text-align: center;
  color: #062549;
}

.social-media-button:hover {
  cursor: pointer;
  background-color: #35c0ed;
  color: #062549;
}

.contact-form {
  position: absolute;
  top: 10%;
  left: 4%;
  width: 40%;
  min-width: 200px;
  height: 80%;
  min-height: 450px;
  background-color: #fff;
  color: #062549;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-block: 20px;
  border-radius: 3px;
}

.form-input {
  width: 100%;
  padding: 9px;
  padding-inline-start: 12px;
  border-radius: 3px;
  border: 1px solid transparent;
  outline: none;
  font-family: inherit;
  background-color: #f5f5f5;
}

.contact-form input:hover,
.contact-form textarea:hover {
  border: 1px solid #35c0ed;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border: 1px solid #9aeba6;
  background-color: transparent;
}

.wide-fields {
  position: relative;
  width: 90%;
}

.double-field {
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.narrow-fields {
  position: relative;
  width: 46%;
}

.form-label,
.form-placeholder {
  position: absolute;
  top: 9px;
  left: 8px;
  cursor: text;
}

.form-label {
  color: #4e4e4ea4;
  padding-inline: 4px;
  opacity: 0;
  transition: font-size 100ms ease-in;
}

.form-placeholder {
  color: #858585a4;
  padding-inline-start: 4px;
}

.form-error-message {
  position: absolute;
  top: 5em;
  left: 1em;
  font-size: 0.6em;
  color: #eba69a;
}

.form-error-message-textarea {
  position: absolute;
  top: 10em;
  left: 1em;
  font-size: 0.6em;
  color: #eba69a;
}

.form-input:focus~.form-label,
.form-input:not(:placeholder-shown).form-input:not(:focus)~.form-label {
  top: -10px;
  left: 10px;
  font-size: 0.8em;
  opacity: 1;
  background-color: #fff;
}

.form-input:not(:placeholder-shown).form-input:not(:focus) {
  background-color: transparent;
  border: 1px solid #c5c4c4;
}

.form-input:not(:placeholder-shown).form-input:not(:focus)~.form-placeholder {
  opacity: 0;
}

.form-input:focus~.form-placeholder {
  display: none;
}

.message {
  resize: none;
  overflow: auto;
}

#continue,
#edit,
#confirm {
  background-color: #35c0ed;
  color: #062549;
  font-size: 1.2em;
  border: none;
  outline: none;
  width: 90%;
  padding: 8px;
  border-radius: 3px;
  transition: background-color 200ms ease-in;
}

#edit {
  width: 22%;
  min-width: 60px;
}

#confirm {
  width: 70%;
}

#continue:hover,
#confirm:hover {
  background-color: #9aeba6;
}

#edit:hover {
  background-color: #eba69a;
}

/* Form when disabled */

.disabled {
  background-color: #f5f5f5 !important;
}

.form-label.disabled {
  pointer-events: none;
}

.disabled:hover {
  border: 1px solid #c5c4c4 !important;
}

#footer {
  min-height: 5%;
  width: 100%;
  overflow: hidden;
  background-color: #9aeba6;
  color: #4e4e4e;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-inline: 5%;
  padding-block: 5px;
  font-size: 85%;
}

#footer a {
  text-decoration: none;
  color: black;
}

/* Mobile Version Tweaks  */

#mobile-menu-button {
  display: none;
  font-size: 2em;
  color: #062549;
  text-align: center;
  margin-right: 20px;
}

#mobile-menu-button:hover {
  color: #35c0ed;
}

@media only screen and (max-width: 720px) {
  #nav-bar-links {
    display: none !important;
  }

  #nav-bar-links-mobile {
    display: flex;
    flex-direction: column;
    position: inherit;
    top: 66px;
    background-color: #062549e7;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
  }

  .nav-bar-link-mobile {
    color: #f5f5f5;
    padding: 15px;
    letter-spacing: 5px;
    text-align: center;
    width: 100%;
    display: block;
    text-decoration: none;
  }

  .nav-bar-link-mobile:hover {
    color: #35c0ed;
  }

  #mobile-menu-button {
    display: inline-block;
  }
}

/* Responsive Design Tweaks */

@media only screen and (max-device-width: 1366px) {

  #home,
  #about {
    background-attachment: scroll;
  }

  #home-main-logo {
    width: 80vw;
    min-width: initial;
    margin: auto;
    padding-top: 30vh !important;
  }

  .arrow {
    display: initial;
  }

  .project-slide-c {
    width: 100%;
    left: 0;
  }

  .project-slide-l {
    width: 100%;
    left: -100%;
  }

  .project-slide-r {
    width: 100%;
    right: -100%;
  }

  .project-slide-c .project-description {
    left: 0;
    width: 100%;
    max-width: 100%;
    text-align: initial;
  }
}

@media only screen and (max-width: 900px) {
  figcaption {
    display: none !important;
  }
}

@media only screen and (max-width: 650px),
(max-height: 600px) {
  #home-main-logo {
    padding-top: 40vh;
  }

  #home-footer {
    display: none !important;
  }
}

@media only screen and (max-width: 900px) {
  #contact {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    height: fit-content;
    min-height: fit-content;
    padding-block: 20px;
  }

  .contact-form {
    width: 90%;
    position: relative;
    top: 0;
    left: 0;
    margin-inline: auto;
    margin-block-end: 10%;
  }

  #contact-card {
    width: 90%;
    height: fit-content;
    min-height: fit-content;
    position: relative;
    top: 0;
    left: 0;
    margin-inline: auto;
    padding-block-end: 30px;
  }

  #contact-card h2 {
    margin-block: 20px;
  }

  #map-shot {
    display: none;
  }

  #contact-details {
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  #contact-details-phone-number,
  #contact-details-email-address {
    margin-block-end: 1em;
  }
}

@media only screen and (max-width: 684px) {
  .project-slide-c .project-description {
    left: 0;
    width: 100%;
    max-width: 100%;
    padding: 10% 8%;
  }

  .project-slide-c .project-description h3 {
    font-size: 110%;
  }

  .project-slide-c .project-description p {
    font-size: 85%;
  }

  .project-slide-c.current .project-description {
    transform: translateY(-350px);
  }

  #footer {
    min-height: 5%;
    width: 100%;
    overflow: hidden;
    background-color: #9aeba6;
    color: #4e4e4e;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-inline: 5%;
    padding-block: 5px;
  }

  #footer div {
    margin: 3px;
  }
}

@media only screen and (max-width: 1200px) {
  .double-field {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 120px;
  }

  .double-field .narrow-fields .form-error-message {
    margin-block-end: 4%;
  }

  .narrow-fields {
    width: 100%;
  }

  #contact {
    min-height: 750px;
  }

  .contact-form {
    min-height: 550px;
  }

  #form-confirm-buttons {
    display: flex;
    flex-direction: row;
    min-height: fit-content;
  }
}

@media only screen and (max-width: 1350px) and (min-width: 900px) {
  #contact-details {
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  #contact-details-phone-number {
    margin-block-end: 1em;
  }
}

@media only screen and (max-width: 500px) {
  #contact-card h2 {
    font-size: 8vw;
  }

  #contact-card {
    font-size: 80%;
  }

  #about-text {
    padding-inline: 0;
  }

  #footer {
    font-size: 70%;
  }
}

@media only screen and (max-width: 300px) {
  #footer-logo-button {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  #social-media-message {
    display: none;
  }

  #social-media-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5em;
  }
}